<template>
    <v-container class="background-login ripple-background" fluid fill-height>
        <!-- <div class="circle xxlarge shade1"></div>
        <div class="circle xlarge shade2"></div>
        <div class="circle large shade3"></div>
        <div class="circle mediun shade4"></div>
        <div class="circle small shade5"></div> -->
        <v-layout  justify-center align-center>
            <v-flex xs12 sm8 md2>

                <!-- Desktop -->
                <v-card-title
                class="text-bold mnj-text px-0 title-desktop"
                >
                    MNJGROUP EMPLOYEE MANAGEMENT SYSTEM (MEMS)
                </v-card-title>

                <!-- Test -->
                <!-- <v-card-title
                class="text-boldwhite--text px-0"
                style="word-break:break-word;font-size:1rem;text-align:center;"
                v-if="$vuetify.breakpoint.smAnDown"
                >
                    MNJGroup EMPLOYEE <br> MANAGEMENT SYSTEM (MEMS)
                </v-card-title> -->

                <!-- Mobile -->
                <!-- <v-card-title
                class="text-bold white--text title-mobile"
                v-if="$vuetify.breakpoint.xs"
                >
                    MNJGroup EMPLOYEE MANAGEMENT SYSTEM (MEMS)
                </v-card-title> -->
                
                <v-card width="auto" class="background-cardLogin justify-center align-center"> 
                    <v-card-title class="justify-center pt-10 primary--text ">
                        <v-img
                            class="mx-2 mb-2 mouse-pointer"
                            src="@/assets/img/others/MNJ-trans.png"
                            max-height="100"
                            max-width="180"
                            contain
                        ></v-img>
                    
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <form @submit.prevent="onSignin">
                                <v-row> 
                                    <v-col xs12 class="pb-0" >
                                        <v-text-field
                                        outlined
                                        dense
                                        color="primary"
                                        id="ic"
                                        class="field-login"
                                        name="Math.random()" 
                                        label="User IC" 
                                        v-model="ic" 
                                        type="text"
                                        required
                                        autocomplete="text"
                                        @keypress="isNumber($event)"
                                        :maxlength="maxNumberIc"
                                        prepend-inner-icon="mdi-account"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col xs12 class="pt-0 pb-0">
                                        <v-text-field
                                        outlined
                                        dense
                                        color="primary"
                                        id="password"
                                        class="field-login"
                                        name="Math.random()"
                                        label="Password"
                                        v-model="password"
                                        type="password"
                                        required
                                        autocomplete="new-password"
                                        prepend-inner-icon="mdi-lock"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <span class="pl-4 primary--text text-bold" style="cursor:pointer;" @click="openModal">
                                        Forgot Password?
                                    </span>
                                </v-row>
                                <!-- <v-row>
                                    <span class="pl-4 primary--text" @click="openModal">Forgot Password?</span>
                                </v-row> -->
                                <v-row> 
                                    <v-dialog
                                    v-model="forgotDialog"
                                    width="400"
                                    height="400"
                                    >
                                    <v-card>
                                        <v-col class="text-center pt-10">
                                            <v-icon large color="primary">mdi-alert-circle</v-icon>
                                        </v-col>
                                        <v-card-title class="justify-center primary--text">Forgot Password </v-card-title>
                                        <v-card-text>
                                            <v-col class="text-center primary--text">
                                                <span>Enter your IC No and we'll send a link to your registered email for password reset process.</span>
                                            </v-col>
                                            <v-col class="pb-0 pt-5">
                                                <v-text-field
                                                outlined
                                                dense
                                                color="primary"
                                                id="ic"
                                                class="field-login"
                                                name="Math.random()" 
                                                label="User IC" 
                                                v-model="ic" 
                                                type="text"
                                                required
                                                autocomplete="text"
                                                @keypress="isNumber($event)"
                                                :maxlength="maxNumberIc"
                                                prepend-inner-icon="mdi-account"
                                                ></v-text-field>
                                            </v-col>
                                            <v-col class="pt-0" style="display:flex;">
                                                <v-checkbox
                                                dense
                                                class="align-center text-xs-center"
                                                v-model="captchaBox"
                                                label="I'm not a robot"
                                                ></v-checkbox>
                                                <v-icon class="ml-2 mb-4 primary--text" v-if="captchaBox == false">mdi-robot-confused</v-icon>
                                                <v-icon class="ml-2 mb-4" v-if="captchaBox == true">mdi-robot-off</v-icon>
                                            </v-col>
                                            <v-col v-show="captchaBox == true">
                                                <v-img class="mt-5" :src="captchaPic"></v-img>
                                                
                                            </v-col>
                                            
                                            <!-- <v-col v-show="captchaBox == true">
                                                <v-btn @click="getCaptcha" width="100%" class="primary--text"><v-icon>mdi-reload</v-icon></v-btn>
                                            </v-col> -->
                                             <v-col v-show="captchaBox == true">
                                                 <v-row>
                                                     <v-col sm="8">
                                                         <v-text-field v-model="captchaWord" dense outlined label="Enter Captcha"></v-text-field>
                                                     </v-col>
                                                     <v-col sm="4">
                                                        <v-btn @click="getCaptcha" width="100%" class="primary--text btnReCaptcha">
                                                            <v-icon v-if="loadingCaptcha == false">mdi-reload</v-icon>
                                                            <v-progress-circular
                                                            v-if="loadingCaptcha == true"
                                                            :width="3"
                                                            :size="20"
                                                            indeterminate
                                                            color="primary"
                                                            ></v-progress-circular>
                                                        </v-btn>
                                                     </v-col>
                                                 </v-row>
                                            </v-col>
                                            <v-col class="pt-0" v-show="captchaBox == true">
                                                <v-btn
                                                width="100%"
                                                class="mb-2"
                                                color="primary"
                                                type="Submit"
                                                @click="validateCaptcha"
                                                :loading="loadingSubmit"
                                                >
                                                    Submit
                                                </v-btn>
                                                <!-- <span v-if="errorMsg != ''" class="red--text text-bold">
                                                    <v-icon class="red--text mr-3">mdi-alert-circle</v-icon>{{this.errorMsg}}
                                                </span> -->
                                                <!-- <span class="red--text text-bold">{{this.blankInputCaptchaMsg}}</span> -->
                                            </v-col>
                                        </v-card-text>
                                    </v-card>
                                    </v-dialog>

                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-btn class="primary" block dark type="submit" :loading="loading">Login</v-btn>
                                    </v-col>
                                </v-row>
                            </form>
                        </v-container>
                        <v-alert type="error" dense v-if="error" :value="true" color="error" icon="mdi-alert" transition="scale-transition">{{ error.message }}</v-alert>
                    </v-card-text>
                </v-card>

                <template>
                    <div class="text-center">

                        <v-snackbar
                        v-model="snackbarError"
                        vertical
                        color="red"
                        timeout="10000"
                        >
                        <span class="text-bold">
                            <v-icon class="white--text mr-3">mdi-alert-circle</v-icon>
                            {{ errorMsg }}
                        </span>

                        <template v-slot:action="{ attrs }">
                            <v-btn
                            color="white"
                            text
                            v-bind="attrs"
                            @click="snackbarError = false"
                            >
                            Close
                            </v-btn>
                        </template>
                        </v-snackbar>
                    </div>
                </template>

                <v-dialog
                v-model="dialogSendEmail"
                persistent
                max-width="500"
                >
                <v-card>
                    <v-card-title class="text-h5 primary--text">
                        Check Email
                        <v-icon class="icon-text primary--text ml-3" large>mdi-email-fast</v-icon>
                    </v-card-title>
                    <v-card-text class="blackk--text">
                        <strong>
                            Reset password link has been sent to your emails. <br> Please check immediately within 15 mins.
                        </strong><br><br>
                        <span>
                            <!-- <ul class="bullet-icon pl-3"> -->
                            <ul>
                                <li>
                                    <!-- <v-icon class="blackk--text mr-1" small>mdi-email-search</v-icon> -->
                                    If you do not received the email, <b>check</b> your <b>junk mail</b> or <b>spam folder</b>.
                                </li>
                                <li>
                                    <!-- <v-icon class="blackk--text mr-1" small>mdi-email-send</v-icon> -->
                                    To avoid MEMS email notification from missing or do not see the email in your inbox, please <b>move email</b> from junk/spam folder <b>into your Inbox</b> or mark email as Not Spam.
                                </li>
                                <li>
                                    <!-- <v-icon class="blackk--text mr-1" small>mdi-account-tie-voice</v-icon> -->
                                    If you still don’t receive an email, please contact HR department.
                                </li>
                            </ul>
                        </span>
                    </v-card-text>
                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-bold primary--text"
                        text
                        @click="dialogSendEmail = false; forgotDialog = false;"
                    >
                        OK
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-dialog>

            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
import axios from "axios"

export default {
    // components: VueRecaptcha,
    data () {
        return {
            ic: '',
            password: '',
            // email: '',
            maxNumberIc: 12,
            showAlert: false,
            forgotDialog: false,
            siteKey: "",
            captchaPic: "",
            captchaWord: "",
            captchaBox: false,
            showCheckbox: false,
            showTextField: false,
            errorAlert: false,
            errorMsg: "",
            blankInputCaptchaMsg: "",
            successMsg: "",
            loadingCaptcha: false,
            loadingSubmit: false,

            dialogSendEmail: false,

            snackbarError: false
        }

    },
    methods: {

        getCaptcha() {

            this.loadingCaptcha = true;

            axios.get(process.env.VUE_APP_API_URL + 'employees/captcha', {
                headers: {
                    Authorization: "Bearer " + null,
                },
            })
            .then((response) => {
                this.siteKey = response.data.id;
                this.captchaPic = response.data.captchablob;
                this.loadingCaptcha = false;
            })
            .catch((error) => { //eslint-disable-line
                // console.log(error);
                this.loadingCaptcha = false;
            });
        },

        validateCaptcha() {

            // console.log(this.captchaWord);
            this.loadingSubmit = true;

            if (this.ic == null || this.ic == "") {
                this.errorMsg = "Please enter your IC"
                this.snackbarError = true;
                this.loadingSubmit = false;
            }
            else{
                if (this.captchaWord == null || this.captchaWord == "") {
                    this.errorMsg = "Please fill the captcha"
                    this.snackbarError = true;
                    this.loadingSubmit = false;
                }
                else{

                    this.errorMsg = ""

                    // axios.get(`${process.env.VUE_APP_API_URL}/users/resetpass?emel=${this.email}&kapchaword=${this.captchaWord}&kapchaid=${this.siteKey}`,
                    axios.get(process.env.VUE_APP_API_URL + 'employees/resetpass?noic='+this.ic+'&kapchaword='+this.captchaWord+'&kapchaid='+this.siteKey,
                    {
                        headers: {
                            Authorization: "Bearer " + null,
                        },
                    })
                    .then((response) => {
                        this.successMsg = response.data;
                        // console.log(this.successMsg);
                        // console.log(response);
                        this.dialogSendEmail = true;
                        // this.close();
                        this.loadingSubmit = false;
                    })
                    .catch((error) => {
                        this.dialogSendEmail = false;
                        // this.errorMsg = "Your IC user doesn't exist in our record";
                        if(error.response.data.message == "wrong kapcha"){
                            this.errorMsg = "You enter the wrong captcha.";
                            this.snackbarError = true;
                            this.loadingSubmit = false;
                        }
                        else if(error.response.data.message == "Your info doesn't exist in our record"){
                            this.errorMsg = "The IC doesn't exist in our record";
                            this.snackbarError = true;
                            this.loadingSubmit = false;
                        }
                        else{
                            this.loadingSubmit = false;
                        }
                        
                        this.errorAlert = true;
                        // console.log(error.response); 
                    });
                }
            }

            

            
        },

        // Text must in numbering type
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },

        onSignin() {
            this.$store.dispatch('signUserIn', { ic: this.ic, password: this.password })
        },

        openModal(){
              this.forgotDialog = true;
        }

    },
    mounted(){
        document.querySelector(".v-application--wrap").style.display = "flex";
        this.getCaptcha();
        //console.log(this.$store.getters.user);
    },
    
    computed: {
        //user() {
            //return this.$store.getters.user
        //},
        loading() {
            return this.$store.getters.loading
        },
        error() {
            return this.$store.getters.error
        },
        //token() {
            //return this.$store.getters.user.token
        //}
    },
    
    watch: {
        // user(value) {
        //     if (value !== null && value !== undefined) {
        //         // this.$router.push('/')
        //     }
        // },

        forgotDialog: function (val) {
            if (val) {
                this.password = "";
                this.ic = "";
                this.captchaBox = false;
                this.captchaWord = "";
            }
            this.blankInputCaptchaMsg = ""
            this.errorMsg = ""
        },
    },
}
</script>

<style>

/* .v-application--wrap {
    display: flex !important;
} */

.title-desktop{
    word-break:break-word;
    font-size:1rem;
    text-align:center !important;
    /* text-shadow: 0px 1px black; */
    text-shadow: 0px 1px #06466E;
}

.title-mobile{
    word-break:break-word;
    font-size:1.12rem;
    text-align:center !important;
    text-shadow: 1px 2px black;
}

.background-cardLogin{
    /* background-image: linear-gradient(to bottom, white, #d1f0ff) !important; */
    background-image: url("~@/assets/img/others/2.jpg") !important;
    background-size: cover;
    background-repeat: no-repeat;
}

.background-login{
    background-image: url("~@/assets/img/others/m.jpg") !important;
    /* background: #086098; */
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.btnReCaptcha{
    background: #b0e0ff !important;
}


/* Animation Background */

body{
  overflow: hidden !important
}


.circle{
  position: absolute;
  border-radius: 50%;
  background: white;
  animation: ripple 15s infinite;
  box-shadow: 0px 0px 1px 0px #508fb9;
}

.small{
  width: 200px;
  height: 200px;
  left: -100px;
  bottom: -100px;
}

.medium{
  width: 400px;
  height: 400px;
  left: -200px;
  bottom: -200px;
}

.large{
  width: 600px;
  height: 600px;
  left: -300px;
  bottom: -300px;
}

.xlarge{
  width: 800px;
  height: 800px;
  left: -400px;
  bottom: -400px;
}

.xxlarge{
  width: 1000px;
  height: 1000px;
  left: -500px;
  bottom: -500px;
}

.shade1{
  opacity: 0.2;
}
.shade2{
  opacity: 0.5;
}

.shade3{
  opacity: 0.7;
}

.shade4{
  opacity: 0.8;
}

.shade5{
  opacity: 0.9;
}

@keyframes ripple{
  0%{
    transform: scale(0.8);
  }
  
  50%{
    transform: scale(1.2);
  }
  
  100%{
    transform: scale(0.8);
  }
}

/* .v-application .mt-5{
    margin-top: 0px !important;
} */



</style>
